<template>
  <div class="ViznetPage">
    <Viznet :token="token" />
  </div>
</template>

<script>
export default {
  name: "ViznetPage",
  data: () => ({
    token: null,
  }),
  created() {
    this.token = sessionStorage.getItem("adal.idtoken");
  },
};
</script>
<style lang="scss">
.ViznetPage {
  top: 73px;
  position: relative;
  z-index: 99;
  left: 200px;
  width: calc(100% - 200px);
}
</style>
