import axios from "axios";

export default {
  get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          },
        })

        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  post(url, payload) {
    let headers = {
      // withCredentials: true,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload, headers)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  postFile(url, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  put(url, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  patch(url, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(url, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  delete(url) {
    let headers = {
      //withCredentials: true,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(url, headers)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
