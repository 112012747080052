import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
/**
 * @mixin
 */
export default {
  props: {
    token: {
      type: String,
      default: () => sessionStorage.getItem("adal.idtoken"),
    },
  },
  computed: {
    headers() {
      const token = sessionStorage.getItem("adal.idtoken");
      return {
        headers: {
          "Content-Type": "application/json",
          "Content-Security-Policy":
            "default-src * 'unsafe-inline' 'unsafe-eval'; script-src * 'unsafe-inline' 'unsafe-eval'; connect-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline'; frame-src *; style-src * 'unsafe-inline'",
          Authorization: "Bearer " + token,
        },
      };
    },
  },
  methods: {
    get(url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url, this.headers)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    post(url, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(url, payload, this.headers)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    delete(url, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(url, { data, headers: this.headers.headers })
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    patch(url, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(url, payload, this.headers)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },
};
