import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        selectedMenu: null,
        selectedSubMenu: null,
        consent: false,
    },
    mutations: {
        UpdateSelectedSubMenu(state, menu){            
            state.selectedSubMenu = menu;
        },
        UpdateSelectedMenu(state, menu){
            state.selectedMenu = menu;
        },
        UpdateConsent(state, value){
            state.consent = value;
        },
    },
    actions: {

    }
})