<template>
  <div class="ReportPage" v-if="isloaded">
    <Bookmarks :token="token" :language="language" />
  </div>
</template>
<script>
window["multirapports"] = window["multirapports"] || {};
export default {
  name: "BookmarkPage",
  data: () => ({
    token: null,
    isloaded: false,
    language: "",
  }),
  created() {
    this.token = sessionStorage.getItem("adal.idtoken");
  },
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;

    this.isloaded = true;
  },
  components: {
    ...window["multirapports"].default,
  },
};
</script>
<style lang="scss">
.ReportPage {
  top: 73px;
  position: relative;
  z-index: 99;
  left: 200px;
  width: calc(100% - 180px);
  overflow-y: scroll;
  min-height: calc(100vh - 72px);
}
@media print {
  .ReportPage {
    left: 0px !important;
    overflow-y: hidden !important;
  }
}
@media (max-width: 480px) and (min-width: 320px) {
  .ReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 34px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 640px) and (min-width: 480px) {
  .ReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 34px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 768px) and (min-width: 640px) {
  .ReportPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 34px;
    width: calc(100% - 34px);
  }
}
</style>
