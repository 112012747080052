<template>
  <v-app >
    <TemplateManagement :language="language"></TemplateManagement>
  </v-app>
</template>
<script>
window["kpmg-dshb2"] = window["kpmg-dshb2"] || {};
export default {
  components: {
    ...window["kpmg-dshb2"].default,
    
  },
  data: () => ({
    language: "",
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
    ? localStorage.getItem("UserLang")
    : this.$i18n.locale;
},
};
</script>
