import { render, staticRenderFns } from "./GestionMenuPage.vue?vue&type=template&id=c91c2346&"
import script from "../../../public/js/img/Discoveryjs/AutorisationJs/GestionMenusPage.js?vue&type=script&lang=js&"
export * from "../../../public/js/img/Discoveryjs/AutorisationJs/GestionMenusPage.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports