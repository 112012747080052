
window["kpmg-notification"] =  window['kpmg-notification']  ||  {}
export default {
  name: "AddNotification",
  components: {
    ...window["kpmg-notification"].default
  },
  data: () => ({
    language: ""
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};