/**
 * @mixin
 */
export default {
  data: () => ({
    getMenuItemsToSidebarURL:
      "/v1/Menus?%24orderby=MenuOrder&$filter=MenuParentId%20eq%20null&$expand=InverseMenuParent($orderby=MenuOrder)",
    RolesItems:
      "/v1/Roles?%24count=false&$expand=creator($select=name,firstname)&$orderby=createdDate desc",
    ScopesByRole: "/v1/RoleScopes?%24count=false&$filter=rolesId%20eq ",
    AddScopesByRole: "/v1/RoleScopes",
    PatchRoles: "/v1/Roles",
    AddRole: "/v1/Roles",
    SecurityScopes:
      "/v1/Applications?%24count=false&$expand=ApplicationModule($expand=ApplicationFeature($expand=securityScopes($expand=scopesDependencies)))",
    MembreStatic1: "/v1/Roles(",
    MembreStatic2:
      ")?%24count=false&$expand=RoleStaticUsers($expand=Users($select=name,firstname,userLogin))",
    AddStaticMember: "/v1/RoleStaticUsers",
    RolesItemsAfetrUpdate:
      "/v1/Roles?%24count=false&$expand=creator($select=name,firstname)&$orderby=ModifiedDate desc",
    Users: "/v1/Users",
    GetContactByMail1: "/v1/Contacts?%24filter=contactEmailOffice%20eq%20'",
    GetContactByMail2:
      "'&$select=contactLastName,contactFirstName,contactEmailOffice,contactFullName,id",

    // get scope connected user
    getconnectedUseScopes1: "/v1/UserScopes?%24filter=userEmail%20eq%20'",
    getconnectedUseScopes2: "'%20and%20startsWith(scopeName%2C%20'",
    getconnectedUseScopes3: "')&$select=scopeName,id&%24count=false",

    // // get contact by id
    // GetRoleContactTiers1:"/v1/Roles?%24filter=contactGUID%20eq ",
    // GetRoleContactTiers2:"&%24count=false",

    // get Account of connected user with expand
    GetAccountConnectedUser1:
      "/v1/Accounts?%24select=accountNumber,accountLegalName,accountRegisterIdentification2,id",

    // // get contact from tiers
    GetContactFromTiers:
      "/v1/Contacts?%24select=contactLastName,contactFirstName,contactEmailOffice,contactPhoneMobileOffice,id,contactType&%24filter=contactType%20ne%20'Collaborateur'",
    //get information from contactClaims
    getContactClaims: "/v1/ContactClaims",

    // get contact from organisaton
    GetContactFromOrganisation1:
      "/v1/Contacts?%24filter=contactEmailOffice%20eq%20'",
    GetContactFromOrganisation2: "'&$select=contactEmailOffice,id,contactType",
    // search contact with name or last name or email from tiers
    GetContactFromTiers1:
      "/v1/Contacts?%24filter= (startsWith(contactFirstName,'",
    GetContactFromTiers2: "') or startsWith(contactLastName,'",
    GetContactFromTiers3: "') or startsWith(contactEmailOffice,'",
    GetContactFromTiers4:
      "'))&$select=contactFullName,contactLastName,id,contactFirstName,contactEmailOffice",

    // // search role from tiers
    GetRoleFromTiers1:
      "/v1/Roles?$filter=roleContactGlobalUniqueIdentifierNavigation/contactType ne 'Collaborateur'&$select=roleFunctionDescription",

    // // search engagement from finance
    GetEngagementFromFinance1:
      "/v1/Engagements?%24filter=engagementAccountNumber%20eq%20'",
    GetEngagementFromFinance2:
      "'%20and%20engagementStatut%20in('dormant' , 'Actif')&$select=engagementCode,engagementDescription,engagementStatut,id",

    // // search offer from catalogue
    GetOfferFromCatalogue:
      "/v1/Offers?%24filter=offerName%20eq%20'multirapports'&select=offerName,offerDescription,id",

    // // get id connected user from autorisation
    getIdConnectedUser1: "/v1/Users?%24filter=userLogin%20eq%20'",
    getIdConnectedUser2: "'&%24count=false",

    // // add OnBoarding
    AddOnBoarding: "/v1/OnBoardingRequest",

    // // get on-boarding request
    getOnBoardingRequest1: "/v1/OnBoardingRequest?%24filter=createdBy%20eq%20",
    getOnBoardingRequest2: "%20or%20emailValidateur%20eq%20'",
    getOnBoardingRequest3:
      "'&%24orderby=createdDate%20desc&$select=id,titre,contactFirstName,contactLastName,contactEmail,accountLegalName,accountIbsId,statut,descriptionEngagement,createdDate",

    // // delete user from autorisation
    deleteroleStaticUser1: "/v1/RoleStaticUsers(",
    deleteroleStaticUser2: ")",

    // modifier on-boarding
    updateOnBoardingRequest1: "/v1/OnBoardingRequest(",
    updateOnBoardingRequest2: ")",

    // get info selected contact by Email
    getinfoContactByEmail1:
      "/v1/OnBoardingRequest/getContactInfoByEmail(ContactEmail='",
    getinfoContactByEmail2: "')",
  }),
  computed: {},
  methods: {},
};
