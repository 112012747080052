<template>
  <div class="ConsultationProjectPage">
    <ConsultationProject :token="token" :language="language" />
  </div>
</template>
<script>
window["multirapports"] = window["multirapports"] || {};
export default {
  name: "ConsultationProjectPage",
  data: () => ({
    token: null,
    language: "",
  }),
  created() {
    this.token = sessionStorage.getItem("adal.idtoken");
  },
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  },
  components: {
    ...window["multirapports"].default,
  },
};
</script>
<style lang="scss">
.ConsultationProjectPage {
  padding: 12px !important;
  padding-left: 0px !important;
  padding-top: 84px !important;
  margin-left: 200px;
  top: initial !important;
  left: initial !important;
  position: initial !important;
  height: 100%;
  z-index: 99;
  width: calc(100% - 180px);
  overflow-y: scroll;
  min-height: calc(100vh - 127px);
  overflow-x: hidden;
  padding-bottom: 74px;
  padding-right: 15px;
}
@media print {
  .ConsultationProjectPage {
    left: 0px !important;
    overflow-y: hidden !important;
  }
}
@media (max-width: 480px) and (min-width: 320px) {
  .ConsultationProjectPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 46px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 640px) and (min-width: 480px) {
  .ConsultationProjectPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 40px;
    width: calc(100% - 34px);
  }
}
@media (max-width: 768px) and (min-width: 640px) {
  .ConsultationProjectPage {
    top: 107px;
    position: relative;
    z-index: 99;
    left: 40px;
    width: calc(100% - 34px);
  }
}
</style>
