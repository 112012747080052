window["kpmg-Signature"] =  window['kpmg-Signature']  ||  {}
export default {
  name: "SignaturePdf",
  components: {
    ...window["kpmg-Signature"].default
  },
  data: () => ({
    language: ""
  }),
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};