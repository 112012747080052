
window["kpmg-ux-role-permissions"] =  window['kpmg-ux-role-permissions']  ||  {}
export default {
  name: "Permissions",
  data: () => ({
    language: ""
  }),
  created() {},
  components: {
    ...window["kpmg-ux-role-permissions"].default
  },
  mounted() {
    this.language = localStorage.getItem("UserLang")
      ? localStorage.getItem("UserLang")
      : this.$i18n.locale;
  }
};
