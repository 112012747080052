import axios from "axios";

axios.interceptors.request.use((config) => {

    return config;
}, (err) => {
    return Promise.reject(err)
})

axios.interceptors.response.use((config) => {

        return config;
    }, (err) => {
        return Promise.reject(err)
    })
    /**
     * @mixin
     */
export default {
    props: {
        token: {
            type: String,
            default: () => sessionStorage.getItem("adal.idtoken")
        }
    },
    methods: {
        get(url) {
            return new Promise((resolve, reject) => {
                axios
                    .get(url, {
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("adal.idtoken")}`
                        }
                    })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        post(url, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(url, payload, {
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("adal.idtoken")}`
                        }
                    })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        delete(url, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(url, {
                        data,
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("adal.idtoken")}`
                        }
                    })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        patch(url, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(url, payload, {
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem("adal.idtoken")}`
                        }
                    })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        }
    }
};